body {
  margin: 0;
  font-family:
    'Rubik',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::before {
  content: '';
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background-image: linear-gradient(66deg, #ff6666 40%, #cc9966 100%);
}


.progressbar {
  background-color: #cc9966;
}
